@tailwind base;
@layer base {
  html {
    @apply text-[#555555];
  } 
  }
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


.container {
  padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1230px;
  }
  }

/* Buttons
------------------------------*/
.px-btn {
  @apply rounded-md px-[20px] py-[10px] text-[14px] uppercase tracking-[2px] font-[500] inline-block ease-in duration-300 cursor-pointer;
}

.px-btn-theme {
  @apply bg-[#ffafcc] text-[#343a40] shadow-[6px_6px_0_-1px] shadow-[#343a40];
}
.px-btn-theme:hover {
  @apply bg-[#343a40] text-white shadow-[6px_6px_0_-1px] shadow-[#ffafcc];
}

.px-btn-dark {
  @apply bg-[#343a40] text-white shadow-[6px_6px_0_-1px] shadow-[#ffafcc];
}
.px-btn-dark:hover {
  @apply bg-[#ffafcc] text-[#343a40] shadow-[6px_6px_0_-1px] shadow-[#343a40];
}

.px-btn-theme2 {
  @apply bg-[#ECF7FF] text-[#343a40] shadow-[6px_6px_0_-1px] shadow-[#A2D2FF];
}
.px-btn-theme2:hover {
  @apply bg-[#A2D2FF] text-[#343a40] shadow-[6px_6px_0_-1px] shadow-[#ECF7FF];
}


/* Background
------------------------------*/
.bg-1 {
  @apply bg-[#BDE0FE] rounded-lg;
}

.bg-2 {
  @apply bg-[#FFC8DD] rounded-lg;
}

.bg-3 {
  @apply bg-slate-900;
}

.bg-4 {
  @apply bg-[#92B5FF];
}

.bg-5 {
  @apply bg-[#C4D39B];
}

.bg-6 {
  @apply bg-[#D2CEFF];
}

/* Header
------------------------------*/
.main-header .navbar li {
  @apply px-[10px];
}
.main-header .navbar li a {
  @apply px-[10px] font-[600] uppercase tracking-[2px] relative leading-[35px] inline-block text-[#343a40] text-[15px] cursor-pointer;
}
.main-header .navbar li a:after {
  @apply content-[''] absolute left-[auto] right-[0] bottom-[0] ease-in duration-300 w-[0] h-[2px] bg-[#FFAFCC];
}
.main-header .navbar li a:hover,
.main-header .navbar li a.active {
  @apply text-[#FFAFCC];
}

.main-header .navbar li a:hover:after,
.main-header .navbar li a.active:after {
  @apply left-[0] right-[auto] w-[100%];
}

.main-header .navbar-collapse {
  flex: 1;
}

.fixed-header .main-header {
  @apply bg-white shadow-sm;
}

@media (max-width: 1023px) {
  .main-header .navbar-collapse {
    @apply absolute left-0 top-[100%] right-0 bg-white hidden;
  }
  .main-header .navbar-collapse.menu-open {
    @apply block;
  }
  .main-header .navbar {
    @apply w-full border-t-[1px] border-t-slate-200;
  }
  .main-header .navbar li {
    @apply py-[5px] px-0;
  }
}

/* Owl
------------------------------*/
.owl-carousel.eq-height .owl-stage {
  @apply flex;
}
.owl-carousel.eq-height .owl-stage .owl-item {
  @apply flex items-center;
}
.owl-carousel .owl-item img {
  @apply w-auto max-w-[100%];
}

.owl-dots {
  @apply text-center;
}

.owl-dots .owl-dot {
  @apply inline-block align-top w-[12px] h-[12px] bg-white border border-solid border-slate-900 ease-in duration-300 rounded-full mx-[4px];
}

.owl-dots .owl-dot.active {
    @apply bg-slate-900;
}

/* Section
------------------------------*/
.section {
  @apply py-[45px] md:py-[60px] lg:py-[100px];
}

.section-heading {
  @apply pb-[30px] md:pb-[40px] lg:pb-[60px];
}
.section-heading h3 {
  @apply text-[35px] md:text-[40px] lg:text-[50px] text-slate-900 font-[600] uppercase leading-[1.2];
}


/* Home Section
------------------------------*/
.home-section::after {
  @apply content-[''] rounded-[50px] rotate-[-40deg] right-[-20%] left-[47%] bottom-[38%] top-[-12%] bg-[#cae6fd] absolute;
}


/* Feature Box
------------------------------*/
.feature-box-01 {
  @apply p-[20px] md:p-[30px] lg:p-[40px] border-2 border-slate-900 flex shadow-[0_0_0_0] shadow-slate-900 ease-in duration-300 hover:shadow-[5px_5px_0_0];
}

.feature-box-01 .icon {
  @apply w-[70px] h-[70px] border-2 border-slate-900 text-slate-900 flex items-center justify-center bg-white text-[32px] rounded-lg;
}

.feature-box-01 .feature-content {
  @apply flex-1 pl-[20px];
}

.feature-box-01 h5 {
  @apply text-[18px] md:text-[20px] lg:text-[24px] text-slate-900 font-[600] mb-[10px] leading-[1.2];
}
.feature-box-01 p {
  @apply text-slate-700;
}

.feature-box-02 {
  @apply p-[15px] border-2 border-slate-900 items-center flex shadow-[0_0_0_0] shadow-slate-900 ease-in duration-300 hover:shadow-[5px_5px_0_0];  
}

.feature-box-02 .icon {
  @apply w-[50px] h-[50px] border-2 border-slate-900 flex items-center justify-center bg-white text-[30px] text-slate-900 rounded-lg;
}

.feature-box-02 h6 {
  @apply text-[16px] text-slate-900 font-[600] pl-[15px];
}

.feature-box-03 {
  @apply p-[20px] md:p-[35px] border-2 border-slate-900 shadow-[0_0_0_0] shadow-slate-900 ease-in duration-300 hover:shadow-[5px_5px_0_0] flex mb-[55px]; 
}

.feature-box-03 .feature-img {
  @apply flex-[0_0_100px] max-w-[100px] h-[100px] overflow-hidden mx-auto border-2 border-solid border-slate-900;
}

.feature-box-03 .icons {
  @apply absolute bottom-0 right-0 m-auto text-slate-900 opacity-10 text-[83px] leading-[1];
}

.feature-box-03 .feature-content {
  @apply flex-1 relative pl-[20px];
}

.feature-box-03 .feature-content p {
  @apply text-[17px] mb-3;
}

.feature-box-03 .feature-content h6 {
  @apply font-[600] text-slate-900 m-0;
}

.feature-box-03 .feature-content span {
  @apply text-[14px];
}

/* Portfolio
------------------------------*/

.portfolio-box .portfolio-img {
  @apply relative rounded-lg border-2 border-solid border-slate-900 ease-in duration-300;
}

.portfolio-img img {
  border-radius: 0.5rem !important;
}

.portfolio-box .portfolio-img .gallery-link-icon {
  @apply absolute w-[40px] h-[40px] bg-white text-slate-900 inline-flex items-center justify-center rounded-lg border-2 border-solid border-slate-900 top-[15px] left-[0] ease-in duration-300 opacity-0;
}

.portfolio-box .portfolio-img:hover {
  @apply shadow-[5px_5px_0_0] shadow-[#343a40] rounded-lg;
}

.portfolio-box .portfolio-img:hover .gallery-link-icon {
  @apply left-[15px] opacity-[1];
}

.portfolio-box .portfolio-text h6 {
  @apply mb-[18px];
}

.portfolio-box .portfolio-text h6 span {
  @apply inline-block px-[20px] py-[8px] bg-[#FFC8DD] text-slate-900 tracking-wider uppercase text-[12px] rounded-[30px];
}

.portfolio-box .portfolio-text h4 {
  @apply text-slate-900 font-[600] text-[25px]  md:text-[32px] mb-[15px] md:mb-[25px] leading-[1.2];
}

.portfolio-box .portfolio-text .btn-bar {
  @apply pt-[25px];
}

.portfolio-box .portfolio-text .px-btn {
  @apply text-[12px];
}


/* Contact us
------------------------------*/
.form-control {
  @apply w-full text-[16px] font-[400] leading-[1.5] text-slate-900 bg-white border border-solid border-slate-900 outline-none px-[12px] py-[10px];
}
.form-label {
  @apply mb-[8px] inline-block;
}

.contact-infos {
  @apply ml-3 p-0 list-none;
}

.contact-infos li {
  @apply flex relative pb-[25px];
}

.contact-infos li:last-child {
  @apply pb-0;
}

.contact-infos .icon {
  @apply w-[55px] h-[55px] inline-flex items-center justify-center text-slate-900 text-[25px];
}

.contact-infos .col {
  @apply flex-1 pl-[15px];
}

.contact-infos h5 {
  @apply text-[14px] mb-2 text-slate-900 text-opacity-80 font-[400] tracking-wider uppercase;
}

.contact-infos p {
  @apply m-0 text-[18px] md:text-[20px] text-slate-900 font-[500];
}

.contact-infos .bg-2 {
  @apply shadow-[0_0_0_0] shadow-[#343a40] ease-in duration-300 hover:shadow-[5px_5px_0_0];
}

.contact-infos .bg-1 {
  @apply shadow-[0_0_0_0] shadow-[#343a40] ease-in duration-300 hover:shadow-[5px_5px_0_0];
}

/* Popup
------------------------------*/
.px-modal {
  @apply fixed top-0 left-0 right-0 bottom-0 pt-[50px] bg-black bg-opacity-75 z-[999] flex justify-center items-center;
}

.px-modal .single-project-box {
  @apply max-w-[1200px] mx-auto bg-white w-[90%] relative flex flex-col;
  max-height: calc(100% - 50px);
}
.px-modal .single-project-box > .grid {
  @apply p-[25px] h-full overflow-y-auto;
}
.px-modal .px-close {
  @apply absolute -top-[18px] -right-[18px] w-[40px] h-[40px] bg-black rounded-full text-white flex items-center justify-center border-2 border-white text-sm;
}